@import './vars';

* {
  box-sizing: border-box;
}

.space-between {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

html, body {
  min-width: 320px;
}
body {
  background-color: #f6f6f6;
}


/// <reference path="app.vars.scss" />

h1, h2, h3, h4, h5, h6 {
  font-weight: 100;
}

h1 {
  font-size: 2.4rem;
}

h2 {
  font-size: 1.7rem;
}

h3 {
  font-size: 1.3rem;
}

label {
  color: $text-color-mute;
  font-size: .9em;
}

select, input {
  border: solid 1px transparent;
  padding: .3em .5em;
  background-clip: padding-box;
}

button {
  border: none;
  padding: .3em 1em;
  background-color: $primary-color;
  color: $primary-color-alt;

  &[disabled] {
    opacity: .5;
  }
}

time {
  font-family: Courier New, Courier, monospace;
}

blockquote {
}

table {
  width: 100%;
}


ol, ul {
  margin: 0 0 0 $list-indent;
  padding: 0;
  counter-reset: item;

  li {
    position: relative;
    display: block;

    &:before {
      position: absolute;
      left: -$list-indent;
      width: $list-indent;
      text-align: right;
      padding: 0 $padding 0 0;
      counter-increment: item;
    }
  }
}

ol[type='1'] {

  li {

    &:before {
      content: counters(item, ".") " ";
    }
  }
}

ol[type='a'] {

  li {

    &:before {
      content: counter(item, upper-alpha);
    }
  }
}



body, select, input, textarea, button {
  font-family: "Segoe UI", Candara, "Bitstream Vera Sans", "DejaVu Sans", "Bitstream Vera Sans", "Trebuchet MS", Verdana, "Verdana Ref", sans-serif;
  font-size: 1rem;
}

a {
  text-decoration: none;
  color: $primary-color;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

header.main {
  padding: 5px;
  min-width: 300px;
  background-color: #eee;
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  right: 0;

  .content {
    max-width: $width-x-large;
    margin: 0 auto;
    padding: 0 6%;
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      min-width: 0;
      margin-right: 4px;

      > * {
        margin-right: 4px;
        min-width: 0;
      }
    }

    :last-child {
      margin-right: 0 !important;
    }

    @media(max-width:450px) {
      padding: 0;
    }
  }

}
