@import '../node_modules/@mufc-aon/muse-core-components/dist/assets/vars.scss';
@import "../node_modules/@mufc-aon/muse-core-components/dist/assets/components";

turnstiles-auth {
  @include normal();
  @include forms();
}

$cell: 40px;
$list-indent: $cell*.75;

$padding: 8px;
$radius: 2px;
$border: 0;
$line: 2px;

$shadow: rgba(0, 0, 0, .1);
$drop-shadow: 0 1px 10px 5px $shadow;

$border-color: #ddd;

$text-color: #444;
$text-color-alt: #eee;

$text-color-mute: #666;

$primary-color: var(--primary-color);
$primary-color-alt: var(--primary-color-alt);

$label-width: 100px;

$input-text-color: $text-color;
$input-background-color: #fff;
$static-background-color: #f0f0f0;

$width-x-large: 2048px;
$width-large: 1400px;
$width-medium: 1024px;
$width-small: 800px;
$width-x-small: 320px;
